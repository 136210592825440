// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-abuse-policy-index-mdx": () => import("./../../../src/pages/abuse-policy/index.mdx" /* webpackChunkName: "component---src-pages-abuse-policy-index-mdx" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-customize-js": () => import("./../../../src/pages/customize.js" /* webpackChunkName: "component---src-pages-customize-js" */),
  "component---src-pages-domains-domain-transfer-check-index-js": () => import("./../../../src/pages/domains/domain-transfer-check/index.js" /* webpackChunkName: "component---src-pages-domains-domain-transfer-check-index-js" */),
  "component---src-pages-lock-account-index-js": () => import("./../../../src/pages/lock-account/index.js" /* webpackChunkName: "component---src-pages-lock-account-index-js" */),
  "component---src-pages-my-account-index-js": () => import("./../../../src/pages/my-account/index.js" /* webpackChunkName: "component---src-pages-my-account-index-js" */),
  "component---src-pages-playground-js": () => import("./../../../src/pages/playground.js" /* webpackChunkName: "component---src-pages-playground-js" */),
  "component---src-pages-privacy-policy-index-mdx": () => import("./../../../src/pages/privacy-policy/index.mdx" /* webpackChunkName: "component---src-pages-privacy-policy-index-mdx" */),
  "component---src-pages-terms-conditions-asia-mdx": () => import("./../../../src/pages/terms-conditions/asia.mdx" /* webpackChunkName: "component---src-pages-terms-conditions-asia-mdx" */),
  "component---src-pages-terms-conditions-au-mdx": () => import("./../../../src/pages/terms-conditions/au.mdx" /* webpackChunkName: "component---src-pages-terms-conditions-au-mdx" */),
  "component---src-pages-terms-conditions-biz-mdx": () => import("./../../../src/pages/terms-conditions/biz.mdx" /* webpackChunkName: "component---src-pages-terms-conditions-biz-mdx" */),
  "component---src-pages-terms-conditions-ca-mdx": () => import("./../../../src/pages/terms-conditions/ca.mdx" /* webpackChunkName: "component---src-pages-terms-conditions-ca-mdx" */),
  "component---src-pages-terms-conditions-cn-mdx": () => import("./../../../src/pages/terms-conditions/cn.mdx" /* webpackChunkName: "component---src-pages-terms-conditions-cn-mdx" */),
  "component---src-pages-terms-conditions-co-mdx": () => import("./../../../src/pages/terms-conditions/co.mdx" /* webpackChunkName: "component---src-pages-terms-conditions-co-mdx" */),
  "component---src-pages-terms-conditions-eu-mdx": () => import("./../../../src/pages/terms-conditions/eu.mdx" /* webpackChunkName: "component---src-pages-terms-conditions-eu-mdx" */),
  "component---src-pages-terms-conditions-highly-regulated-mdx": () => import("./../../../src/pages/terms-conditions/highly-regulated.mdx" /* webpackChunkName: "component---src-pages-terms-conditions-highly-regulated-mdx" */),
  "component---src-pages-terms-conditions-index-mdx": () => import("./../../../src/pages/terms-conditions/index.mdx" /* webpackChunkName: "component---src-pages-terms-conditions-index-mdx" */),
  "component---src-pages-terms-conditions-info-mdx": () => import("./../../../src/pages/terms-conditions/info.mdx" /* webpackChunkName: "component---src-pages-terms-conditions-info-mdx" */),
  "component---src-pages-terms-conditions-mobi-mdx": () => import("./../../../src/pages/terms-conditions/mobi.mdx" /* webpackChunkName: "component---src-pages-terms-conditions-mobi-mdx" */),
  "component---src-pages-terms-conditions-name-mdx": () => import("./../../../src/pages/terms-conditions/name.mdx" /* webpackChunkName: "component---src-pages-terms-conditions-name-mdx" */),
  "component---src-pages-terms-conditions-performance-tuning-mdx": () => import("./../../../src/pages/terms-conditions/performance-tuning.mdx" /* webpackChunkName: "component---src-pages-terms-conditions-performance-tuning-mdx" */),
  "component---src-pages-terms-conditions-rdap-mdx": () => import("./../../../src/pages/terms-conditions/rdap.mdx" /* webpackChunkName: "component---src-pages-terms-conditions-rdap-mdx" */),
  "component---src-pages-terms-conditions-rebel-website-design-mdx": () => import("./../../../src/pages/terms-conditions/rebel-website-design.mdx" /* webpackChunkName: "component---src-pages-terms-conditions-rebel-website-design-mdx" */),
  "component---src-pages-terms-conditions-sucks-mdx": () => import("./../../../src/pages/terms-conditions/sucks.mdx" /* webpackChunkName: "component---src-pages-terms-conditions-sucks-mdx" */),
  "component---src-pages-terms-conditions-us-mdx": () => import("./../../../src/pages/terms-conditions/us.mdx" /* webpackChunkName: "component---src-pages-terms-conditions-us-mdx" */),
  "component---src-pages-terms-conditions-xxx-mdx": () => import("./../../../src/pages/terms-conditions/xxx.mdx" /* webpackChunkName: "component---src-pages-terms-conditions-xxx-mdx" */),
  "component---src-templates-feature-page-js": () => import("./../../../src/templates/feature-page.js" /* webpackChunkName: "component---src-templates-feature-page-js" */),
  "component---src-templates-panel-page-js": () => import("./../../../src/templates/panel-page.js" /* webpackChunkName: "component---src-templates-panel-page-js" */),
  "component---src-templates-product-page-js": () => import("./../../../src/templates/product-page.js" /* webpackChunkName: "component---src-templates-product-page-js" */),
  "component---src-templates-tlds-template-js": () => import("./../../../src/templates/tlds-template.js" /* webpackChunkName: "component---src-templates-tlds-template-js" */)
}

